import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Menu.css";
class Menu extends Component {
  render() {
    return (
      <div className={this.handleMenuStyle()}>
        <section className="menuSection container-fluid">
          <div className="row">
              <div className="menuBackImage"></div>
              <div className="menuBack">
                <Link className="menuText" to="/"  onClick={() => this.props.changeMenu()}> Home</Link>
                <h1 className="menuText">The Building</h1>
                <h1 className="menuText">Spaces</h1>
                <h1 className="menuText">Neighborhood</h1>
                <h1 className="menuText">Contact</h1>
              </div>
              <div className="menuDetails">
                <div className="menuDetail">
                  <h1 className="menuDetailHeaderText">Address</h1>
                  <p className="menuDetailText">4252 8th Ave NE<br/>Seattle WA, 98105</p>
                </div>
                <div className="menuDetail">
                  <h1 className="menuDetailHeaderText">Phone</h1>
                  <p className="menuDetailText">425-445-8885</p>
                </div>
                <div className="menuDetail">
                  <h1 className="menuDetailHeaderText">EMAIL</h1>
                  <p className="menuDetailText">Howe8866@hotmail.com</p>
                </div>
                <div className="menuDetail">
                  
                <i className="fa fa-facebook round" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;
                <i className="fa fa-instagram round" aria-hidden="true"></i>
                </div>
              </div>
            </div> 
        </section>
        <section className="menuSection2 container-fluid">
          <div className="row menuBackImage1">
            <div className="col alignMenu2">
                <h1 className="menuText">Home</h1>
                <h1 className="menuText">The Building</h1>
                <h1 className="menuText">Spaces</h1>
                <h1 className="menuText">Neighborhood</h1>
                <h1 className="menuText">Contact</h1>
            </div>
          </div>

          <div className="row menuDetails2 text-center ">
            <div className="col text-center menuSub">
                <h1 className="menuDetailHeaderText">Address</h1>
                <p className="menuDetailText">4252 8th Ave NE<br/>Seattle WA, 98105</p>
            </div>
            <div className="col text-center menuSub">
                <h1 className="menuDetailHeaderText">Phone</h1>
                <p className="menuDetailText">425-445-8885</p>
            </div>
            <div className="col text-center menuSub">
                <h1 className="menuDetailHeaderText">EMAIL</h1>
                <p className="menuDetailText">Howe8866@hotmail.com</p>
            </div>
          </div>
          <div className="row menuDetails3">
            <div className="col text-center">      
              <i className="fa fa-facebook round" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;
              <i className="fa fa-instagram round" aria-hidden="true"></i>
            </div>
          </div>
        </section>
      </div>
    );
  }
  handleMenuStyle() {
    let classes = "";
    
    classes += this.props.menuOn === 1 ? "menu" : "menu hide";
    return classes;
  }
}
export default Menu;
