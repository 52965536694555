import React, { Component } from "react";
import "./App.css";
import Body from "./component/Body/Body";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
// import axios from "axios";
import Menu from "./component/Menu/Menu";
import NavBar from "./component/NavBar/NavBar";
import AvailableBody from "./component/AvailableBody/AvailableBody";
class App extends Component {
  state = {
    menu: 0,
    menuClick: 0,
    available: 1,
    data: {
      id: "001",
      name: "Sora",
      logoPath: "logo1.png",
      phone: "+1 (425) 890-9526",
      address: "4239 8th Ave, Seattle WA, 98105",
      email: "info@axallant.com",
      kamaial: "Building by Kamiak Real Estate LLC © 2021 4252 8TH NE LLC",
      fb: "https://www.facebook.com/",
      instra: "https://www.instagram.com/",
    },
    propertyData: [],
  };

  componentDidMount() {
    // axios
    //   .get(`https://api.axallant.com/api/property/66/listing`)
    //   .then((res) => {
    //     const propertyData = res.data;
    //     this.setState({ propertyData });
    //   });
  }
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <NavBar changeMenu={this.handleChangeMenu} menu={this.state.menu} />
          <Menu menuOn={this.state.menu} changeMenu={this.handleChangeMenu} />
          <Switch>
            <Route exact path="/">
              <Body menuOn={this.state.menu} footerData={this.state.data} />
            </Route>
            <Route path="/available">
              <AvailableBody
                property={this.state.propertyData}
                name="available"
                menuOn={this.state.menu}
                footerData={this.state.data}
              />
            </Route>
            <Route path="/contact">
              <AvailableBody
                property={this.state.propertyData}
                name="available"
                menuOn={this.state.menu}
                footerData={this.state.data}
              />
            </Route>
            <Redirect push to="/" />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
  handleChangeMenu = () => {
    this.setState({ menu: this.state.menu === 0 ? 1 : 0 });
  };
}

export default App;
