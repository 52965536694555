import React from "react";
import { Link } from "react-router-dom";
import "./HomeContent.css";

const HomeContent = () => {
  return (
    <>
    <section className="homeSection">
        <div className="homeBack opacityAnimation">
            <h1 className="mainText homeText">Live Smart<br/>
                in the U‑District</h1>
            <h1 className="contentText">Located in Seattle’s University District, the Sora is a modern, 
            luxury studios steps from the University District Light Rail Station and the University of Washington Campus.</h1>
            <Link  className="whiteButton" to="/available">Learn More</Link>
        </div>
        <div className="homeBack1">
          <div className=" backImage opacityAnimation">
          </div>
           <div className="homeContent primaryBackground">
           <h1 className="mainTextHome">Live Smart<br/>
                in the U‑District</h1>
            <h1 className="contentTextHome">Located in Seattle’s University District, the Sora is a modern,
            luxury studios steps from the University District Light Rail Station and the University of Washington Campus</h1>

            <Link  className="whiteButton" to="/available">Learn More</Link>
           </div>
        </div>
    </section>
    
</>
  );
};

export default HomeContent;
