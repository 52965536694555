import React from "react";
import { Link } from "react-router-dom";
import "./Neighborhood.css";

const Neighborhood = () => {
  return (
     <section className="neighborhoodSection">
        <div className="container-fluid" >
            <div className="row neighborhood2 opacityAnimation">
                <div className="col neighborhoods  text-center">
                     <h1 className="   textNeighborhood "> neighborhood</h1>
                     <div className="contentPro">  <h1 className="contentTextNeighborhood">The University District is one of Seattle's best-located neighborhoods with walkable amenities and great access to public transportation.  </h1></div>
                     <Link  className="contentButton" to="/available">Learn More</Link>
               
                </div>
               
                <div  className=" col">
                    {/* <div className=" neighborhoodsPoster absolute ">
                      
                    </div> */}
                </div>
            </div>
           
        </div>
         
    </section>
  );
};

export default Neighborhood;
