import React from "react";
import { Link } from "react-router-dom";
import "./BuildingContent.css";
import Building from "./images/soraImage2.jpg";

const BuildingContent = () => {
  return (
     <section className="buildingSection">
         <div className="build">
            <div className="container-fluid" >
                <div className="row building1">
                    <div className="col building">
                        <img src={Building} className="building opacityAnimation"  alt="building" />
                    </div>
                
                    <h1 className="mainText textPrimary absolute  mainPosition">The <br/>Building</h1>
                    
                    <div  className=" col-10">
                        <div className=" buildingPoster absolute ">
                            <p className="posterText buildContent">
                            Being  and functional is what's great about the Sora.  We're a 54-unit community of well-constructed and well-configured studio homes.  
                            </p>
                            <Link  className="primaryButton" to="/available">Why the Sora</Link>
                           
                        </div>
                    </div>
                </div>
            </div>
         </div>
         <div className="build1">
          <div className="buildBackImage opacityAnimation">
          </div>
           <div className="buildContent2">
           <h1 className="mainTextBuild">The <br/>Building<br/></h1>
            <h1 className="contentTextBuilt "> Being  and functional is what's great about the Sora.  We're a 54-unit community of well-constructed and well-configured studio homes.  </h1>
            <Link  className="primaryButton" to="/available">Why the Sora</Link>
           </div>
        </div>
    </section>
  );
};

export default BuildingContent;
