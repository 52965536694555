import React from "react";
import "./InterestApply.css";

const InterestApply = () => {
  return (
     <section id="interestApply" className="InterestedSection mt-5">
       <div className="Box text-center">
       <h1 className="interestHeading">Interested in<br/>
            Applying?</h1>
            <p className="interestContent">Apply Online !</p>
            <div className="row">
              <div className="col text-center"> 
              <a href="/contact" className="primaryButton">Contact us</a>
              
              </div>
            </div>
       </div>
    </section>
  );
};

export default InterestApply;
