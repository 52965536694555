import React, { Component } from "react";
import "./AvailableUnit.css";
import NoData from "./images/preview.png";

class AvailableUnit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalData: [],
      images: [],
      spinner: true,
      soraDetails: [
        {
          image:
            "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/002-Lease-Plan-Image.png",
          name: "APT 002",
          unit: "002",
          plan: "002 SE Lower",
          sq: "303 sq. ft.",
          price: "1295",
          pdf: "https://soraseattle.com/uploads/primary/002-lease-plan.pdf",
          Features: [
            "1 Bath",
            "Quartz counters",
            "Washer / dryer",
            "Plank flooring",
          ],
        },
        // {
        //   image:
        //     "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/003-Lease-Plan-Image.png",
        //   name: "APT 003",
        //   unit: "003",
        //   plan: "003 SW Lower",
        //   sq: "326 sq. ft.",
        //   price: "1265",
        //   pdf: "https://soraseattle.com/uploads/primary/003-lease-plan.pdf",
        //   Features: [
        //     "1 Bath",
        //     "Quartz counters",
        //     "Washer / dryer",
        //     "Plank flooring",
        //   ],
        // },
        {
          image:
            "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/101-Lease-Plan-Image.png",
          name: "APT 101",
          unit: "101",
          plan: "101 Level 1",
          sq: "277 sq. ft.",
          price: "1350",
          pdf: "https://soraseattle.com/uploads/primary/101-lease-plan.pdf",
          Features: [
            "1 Bath",
            "Quartz counters",
            "Washer / dryer",
            "Plank flooring",
          ],
        },
        // {
        //   image:
        //     "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/103-Lease-Plan-Image.png",
        //   name: "APT 103",
        //   unit: "103",
        //   plan: "103 Level 1",
        //   sq: "256 sq. ft.",
        //   price: "1340",
        //   pdf: "https://soraseattle.com/uploads/primary/103-lease-plan.pdf",
        //   Features: [
        //     "1 Bath",
        //     "Quartz counters",
        //     "Washer / dryer",
        //     "Plank flooring",
        //   ],
        // },

        // ora

        {
          image:
            "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/701-Lease-Plan-Image.png",
          name: "APT 701",
          unit: "701",
          plan: "701 Loft Level 7",
          sq: "375 plus loft sq. ft.",
          price: "1915",
          pdf: "https://soraseattle.com/uploads/primary/701-lease-plan.pdf",
          Features: [
            "1 Bath",
            "Quartz counters",
            "Washer / dryer",
            "Plank flooring",
          ],
        },

        // {
        //   image:
        //     "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/704-Lease-Plan-Image.png",
        //   name: "APT 704",
        //   unit: "704",
        //   plan: "704 Loft Level 7",
        //   sq: "450 plus loft sq. ft.",
        //   price: "2265",
        //   pdf: "https://soraseattle.com/uploads/primary/704-lease-plan.pdf",
        //   Features: [
        //     "1 Bath",
        //     "Quartz counters",
        //     "Washer / dryer",
        //     "Air Conditioning",
        //     "Plank flooring",
        //     "High ceilings with ladder loft",
        //   ],
        // },
        {
          image:
            "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/705-Lease-Plan-Image.png",
          name: "APT 705",
          unit: "705",
          plan: "705 Loft Level 7",
          sq: "316 plus loft sq. ft.",
          price: "1715",
          pdf: "https://soraseattle.com/uploads/primary/705-lease-plan.pdf",
          Features: [
            "1 Bath",
            "High ceilings with ladder loft",
            "Air conditioning",
            "Quartz counters",
            "Washer / dryer",
            "Plank flooring",
          ],
        },
      ],
    };
  }

  handelDetail(data1) {
    this.setState({ images: [] });
    this.setState({ modalData: data1 });
    this.setState({ spinner: false });
  }
  sendAxallant(id) {
    const url = "https://axallant.com/sora/application/" + id;
    window.location.replace(url);
  }
  mapFeatures(data) {
    // console.log(data);
    var items = [];
    for (var i = 0; i < data.length; i++) {
      items.push(
        <li className="fontStyle" key={i}>
          {data[i]}
        </li>
      );
    }
    return items;
  }
  render() {
    // var modalHeight = {
    //   height: window.innerHeight - (window.innerHeight * 5) / 100,
    // };
    // var modalMaxHeight={
    //   maxHeight:window.innerHeight-window.innerHeight*25/100,
    // };
    return (
      <>
        <section id="" className="availableUnitSection container-fluid">
          <h1 className="textAvailableUnit pt-3">Available Units</h1>

          {this.props.property ? (
            <div className="availableUnitTable">
              <div className="row availableUnitTableHead ">
                <div className="col text-left">
                  <h1 className="availableTableHead">UNIT NUMBER</h1>
                </div>
                <div className="col">
                  <h1 className="availableTableHead">FLOOR PLAN </h1>
                </div>
                <div className="col">
                  <h1 className="availableTableHead">RENT</h1>
                </div>
                <div className="col">
                  <h1 className="availableTableHead">SQUARE FEET</h1>
                </div>
                <div className="col-1"> </div>
                <div className="col-1"> </div>
              </div>
              <div className="availableTable">
                {this.state.soraDetails.map((data, index) => (
                  <div className="row availableUnitTableRow pb-4" key={index}>
                    <div className="col">
                      <p
                        className="availableTableContent"
                        data-toggle="modal"
                        onClick={() => this.handelDetail(data)}
                        data-target="#exampleModal"
                      >
                        {data.unit}
                      </p>
                    </div>
                    <div
                      className="col"
                      data-toggle="modal"
                      onClick={() => this.handelDetail(data)}
                      data-target="#exampleModal"
                    >
                      <p className="availableTableContent brownYellow">
                        {data.plan}
                      </p>
                    </div>
                    <div
                      className="col"
                      data-toggle="modal"
                      onClick={() => this.handelDetail(data)}
                      data-target="#exampleModal"
                    >
                      <p className="availableTableContent">
                        <i className="fa fa-usd" aria-hidden="true"></i>
                        {data.price}
                      </p>
                    </div>
                    <div
                      className="col"
                      data-toggle="modal"
                      onClick={() => this.handelDetail(data)}
                      data-target="#exampleModal"
                    >
                      <p className="availableTableContent">{data.sq}</p>
                    </div>
                    <div className="col-1 text-right p-0">
                      {" "}
                      <span
                        className="primaryAvailableUnitButton"
                        data-toggle="modal"
                        onClick={() => this.handelDetail(data)}
                        data-target="#exampleModal"
                      >
                        {" "}
                        Details
                      </span>
                    </div>
                    <div className="col-1 text-right p-0">
                      <span
                        data-dismiss="modal"
                        onClick={() => this.sendAxallant(data.unit)}
                        className="availableUnitButton"
                      >
                        Apply
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <br></br>
            </div>
          ) : (
            <div className="row text-center">
              <div className="col">
                <img src={NoData} className="" alt="building" />
              </div>
            </div>
          )}
        </section>
        <section className="availableUnitSection2 container-fluid">
          <h1 className="textAvailableUnit">Available Units</h1>

          {this.state.soraDetails ? (
            <div className="availableTable">
              {this.state.soraDetails.map((data, index) => (
                <div className="availableTableRow " key={index}>
                  <div className="row">
                    <div className="col">
                      <p className="availableTableContent">{data.unit}</p>
                    </div>
                    <div
                      className="col"
                      data-toggle="modal"
                      onClick={() => this.handelDetail(data)}
                      data-target="#exampleModal"
                    >
                      <p className="availableTableContent brownYellow">
                        {data.plan}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="availableTableContent">
                        <i className="fa fa-usd" aria-hidden="true"></i>
                        {data.price}
                      </p>
                    </div>
                    <div className="col">
                      <p className="availableTableContent">{data.sq}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col ">
                      <span
                        data-toggle="modal"
                        onClick={() => this.handelDetail(data)}
                        data-target="#exampleModal"
                        className="primaryAvailableUnitButton"
                      >
                        Details
                      </span>
                    </div>
                    <div className="col">
                      {/* <a
                          href={
                            "https://axallant.com/sora/application/" +
                            data.rentalEntity.id
                          }
                          className="availableUnitButton"
                        >
                          Apply
                        </a> */}
                      <span
                        data-dismiss="modal"
                        onClick={() => this.sendAxallant(data.unit)}
                        className="availableUnitButton"
                      >
                        Apply
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="row text-center">
              <div className="col">
                <img
                  src={NoData}
                  style={{ width: "300px", height: "300px" }}
                  alt="building"
                />
              </div>
            </div>
          )}
        </section>

        {this.state.modalData.length !== 0 && (
          <div
            className="modal fade bd-example-modal-xl"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header shadow  ModalAvailable">
                  <h5
                    className="modal-title textAvailableUnitModal"
                    id="exampleModalLabel"
                  >
                    Unit Detail
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    style={{ color: "#f2360c" }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body bodyAvailable pt-0  pb-0">
                  <div className="row ">
                    <div className="col-8 detailAvailable d-block">
                      <div className="row ">
                        <div className="col img-wrap p-0">
                          {/* <img src={"data:image/jpeg;base64,"+this.state.images[0]} className="imageAvailable" alt=""/> */}
                          {/* <img src={this.state.images[0]} className="imageAvailable" alt=""/> */}
                          {/* <iframe title="videoList" className="imageAvailable" width="715" height="402" src="https://www.youtube.com/embed/zCubzVKczOc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className={this.state.spinner ? "lds-roller" : " "}
                        >
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>

                        <div className="col img-wrap p-0">
                          <img
                            src={this.state.modalData.image}
                            alt=""
                            className="imageAvailable"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col detailAvailable">
                      <h3 className="detailsHeadModal1">
                        {this.state.modalData.name}
                      </h3>
                      <h3 className="detailsHeadModal2">
                        {this.state.modalData.plan}
                      </h3>
                      <h5 className="detailContentAvailable">
                        {this.state.modalData.sq}{" "}
                      </h5>
                      <h5 className="detailContentAvailable">
                        <i className="fa fa-usd" aria-hidden="true"></i>
                        {this.state.modalData.price}{" "}
                      </h5>
                      <span
                        className="fontStyle"
                        style={{ fontSize: "13px", lineHeight: "13px" }}
                      >
                        Sora will be opening August 2021!
                      </span>
                      <hr></hr>
                      <h1 className="availableTableHead1 ">Downloads</h1>
                      <a
                        href={this.state.modalData.pdf}
                        className="availableTableHead2"
                      >
                        PDF FLOOR PLAN WITH DIMENSIONS
                      </a>
                      <br></br>
                      <br></br>

                      <h1 className="availableTableHead1">AVAILABILITY</h1>
                      <p className="fontStyle">
                        {" "}
                        &nbsp; &nbsp; &nbsp; call for details
                      </p>
                      <h1 className="availableTableHead1">Features</h1>

                      <ul>{this.mapFeatures(this.state.modalData.Features)}</ul>
                      <h1 className="availableTableHead1">
                        Community Amenities
                      </h1>
                      <ul>
                        <li className="fontStyle">Laundry Facilities</li>
                        <li className="fontStyle">Controlled Access</li>
                        <li className="fontStyle">Furnished Units Available</li>
                        <li className="fontStyle">Trash Pickup - Curbside</li>
                        <li className="fontStyle">Recycling</li>
                        <li className="fontStyle">Renters Insurance Program</li>
                        <li className="fontStyle">Key Fob Entry</li>
                        <li className="fontStyle">Elevator</li>
                        <li className="fontStyle">Roof Terrace</li>
                        <li className="fontStyle">Grill</li>
                        <li className="fontStyle">Study Lounge</li>
                        <li className="fontStyle">Walk To Campus</li>
                      </ul>
                      <h1 className="availableTableHead1">
                        Apartment Features
                      </h1>
                      <ul>
                        <li className="fontStyle">
                          High Speed Internet Access
                        </li>
                        <li className="fontStyle">Wi-Fi</li>
                        <li className="fontStyle">Heating</li>
                        <li className="fontStyle">Kitchen</li>
                        <li className="fontStyle">Microwave</li>
                        <li className="fontStyle">Refrigerator</li>
                        <li className="fontStyle">Window Coverings</li>
                        <li className="fontStyle">Hardwood Floors</li>
                      </ul>
                      <h1 className="availableTableHead1">
                        Property Information
                      </h1>
                      <ul>
                        <li className="fontStyle">Furnished</li>
                        <li className="fontStyle">Built in 2021</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="modal-footer pt-2 shadow1"
                  style={{ borderTop: "1px solid #d15428" }}
                >
                  <div className="col-8 detailsFooter text-center">
                    <p className="pull-left detailsHead  m-0 p-0 ">
                      {/* Hurry up, reserve your unit now! */}
                    </p>
                  </div>
                  <div className="col text-right m-0 p-0">
                    {" "}
                    <span
                      className="availableUnitButtonModal pull-right  mt-1"
                      data-dismiss="modal"
                      onClick={() =>
                        this.props.handelLeadBox(this.state.modalData)
                      }
                    >
                      {" "}
                      More Details
                    </span>
                  </div>
                  <div className="col text-left m-0 p-0">
                    {" "}
                    <span
                      data-dismiss="modal"
                      onClick={() =>
                        this.sendAxallant(this.state.modalData.unit)
                      }
                      className="primaryAvailableUnitButtonModal  mt-1 pull-left"
                    >
                      Apply Now
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default AvailableUnit;
