import React, { Component } from 'react';
import Interested from './Interested/Interested';
import AvailableContent from './AvailableContent/AvailableContent';
import './Body.css';
import BuildingContent from './BuildingContent/BuildingContent';
import HomeContent from './HomeContent/HomeContent';
import Neighborhood from './Neighborhood/Neighborhood';
import Footer from '../Footer/footer';
class Body extends Component {
  
  render() {
    
    return (
      <div className={this.handleBodyStyle()} >
          <HomeContent/>
          <BuildingContent/>
          <AvailableContent/>
          <Neighborhood/>
          <Interested/>
          <Footer data={ this.props.footerData}/>
        
      </div>
    );
  }
  handleBodyStyle() {
    let classes = "";
    window.scroll(0, 0);
    classes += this.props.menuOn === 1 ? "willetBody hide" :"willetBody ";
    return classes;
  }
}
export default Body;