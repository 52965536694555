import React, { Component } from "react";
import "./NavBar.css";
import { Link  } from "react-router-dom";
import {withRouter} from "react-router-dom"

class NavBar extends Component {
  state={
    available:1,
  }
  render() {
   
    return (
      <div className="nav header ">
        <div className="">
          <div className="col logoPart navLeft"  style={{ zIndex:"5" }}>
            <div   onClick={() => this.handleLogoClick()}>
            <img
              src={this.props.menu === 1 ? "logo.png" : "logo1.png"}
              className={this.props.menu === 1 ? "logo opacityAnimation1" : "logo"}  
              alt="logo"
              
            />
            </div>
            
          </div>
          
          <div className="col text-right pull-right navRight" style={{ zIndex:"3" }}>
            
            <Link  className="availableButton textPrimary transformAvailability" to="/available"  >  {this.props.menu === 1 ||this.props.location.pathname==="/available" ? "" : "Availability"}</Link>

            <span
              onClick={() => this.props.changeMenu()}
              className="menuButton" >
              <i
                className={
                  this.props.menu === 1
                    ? "fa fa-plus menuPlus turnClose"
                    : "fa fa-plus menuPlus  turnMenu"
                }
                aria-hidden="true"
              ></i>
              &nbsp;
              <span className={this.props.menu === 1 ? "menuClose" : "menuClose opacityAnimation"}>
                {this.props.menu === 1 ? "Close" : "Menu"}
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  }
  handleLogoClick() {
    window.location.href = "/";
  }
}
export default withRouter(NavBar);
