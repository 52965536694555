import React from "react";
import "./Interested.css";

const Interested = () => {
  return (
    <section className="InterestedSection">
      <div className="Box text-center">
        <h1 className="interestHeading">
          Interested in
          <br />
          Living here?
        </h1>
        <p className="interestContent">We've made it easy to apply online. </p>
        <div className="row">
          <div className="col-6 text-right">
            <a href="/available" className="primaryButton">
              Apply Online
            </a>
          </div>
          <div className="col-6 text-left">
            <a href="/contact" className="primaryButton">
              Contact us
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Interested;
