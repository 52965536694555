import React from "react";
import "./Footer.css";

const Footer = (props) => {
    const {logoPath,name,phone, address,email,kamaial,fb,instra} = props.data;

  return (
     <section id="footer_section" className="FooterSection text-center container-fluid">
         
         <div className="row officialLogo">
             <div className="col">
             <img
              src= {logoPath}
              className="footerLogo"
              alt="logo"
            />
            <h1 className="logoTitle">{name}</h1>
            <p className="logoSubTitle">Apartments</p>
             </div>
         </div>
         <div className="row menuHead">
             <div className="col menuSubHead">
             <h1 className="footerMenu">Home</h1>
             </div>
             <div className="col menuSubHead">
             <h1 className="footerMenu">The Building</h1> 
             </div>
             <div className="col menuSubHead">
             <h1 className="footerMenu">Spaces</h1>
            </div>
            <div className="col menuSubHead">
            <h1 className="footerMenu">Neighborhood</h1> 
            </div>
            <div className="col menuSubHead">
            <h1 className="footerMenu">Contact</h1>
            </div>
         </div>
         <div className="row footerDetail">
             <div className="col footerSubDetail">
                <div className="alignDetail">
                    <h1 className="footerDetailHead ">PHONE</h1>
                     <p className="footerDetailContent"> { phone} </p>
                 </div>
            
             </div>
             <div className="col footerSubDetail">
                 <div className="alignDetail">
                     <h1 className="footerDetailHead">ADDRESS</h1> 
                    <p className="footerDetailContent">{address}</p>
                 </div>
            
             </div>
             <div className="col footerSubDetail">
                <div className="alignDetail ">
                    <h1 className="footerDetailHead">EMAIL</h1>
                    <p className="footerDetailContent">{email}</p>
                 </div>
            </div>
         </div>
         <div className="row footerDetailOfficial">
             <div className="col footerSubDetailOfficial">
                <div className=" alignDetail alignKamaik">
                    <h1 className="footerDetailHead text-light">Kamaial</h1>
                     <p className="footerDetailContent2 ">{kamaial}</p>
                 </div>
            
             </div>
             <div className="col footerSubDetailOfficial">
                 <div className="alignDetail">
                 
                 <a href={fb}><i className="fa fa-facebook round" aria-hidden="true"></i> </a>
                 <a href={instra}><i className="fa fa-instagram round" aria-hidden="true"></i> </a>

                 </div>
             </div>
             <div className="col footerSubDetailOfficial">
                <div className="alignDetail">
                    <h1 className="footerDetailContent2">Web Design by Axallant</h1>
                    <p className="footerDetailContent"><b>Axallant (Pvt) Ltd.</b></p>
                 </div>
            </div>
            
         </div>
         <div className="row text-center"> 
            <div className="col footerPara">
                <p className="footerDetailContent2 ">This property does not accept portable background reports as defined by and pursuant to RCW 59.18.</p>
            </div>
        </div>
         <div className="row"> 
          <div className="col text-right">
            <i className="fa fa-wheelchair iconFooter" aria-hidden="true"></i>
          </div>
          <div className="col text-left">
          <i className="fa fa-h-square iconFooter" aria-hidden="true"></i>
          </div>
         </div>
         
        
    </section>
  );
};

export default Footer;
