import React from "react";
import { Link } from "react-router-dom";
import "./AvailableContent.css";
import Availables from './images/availables.jpg';

const AvailableContent = () => {
  return (
     <section className="AvailableSection">
         <div className="available">
            <div className="container-fluid" >
                <div className="row " >
                    <div  className=" col availables2 absolute">
                        <div className=" availablePoster absolute ">
                            <p className="posterText availableContent">
                            At the Sora you'll enjoy bright, open studios equipped with a Murphy Bed, built-in desk, tons of shelving, large kitchens, and in-unit washer and dryer. Studio living should come with all the bells and whistles. 
                            </p>
                    
                            <Link  className="contentButton1 pb" to="/available">See The Spaces</Link>
                        </div>
                    </div>
                    <h1 className="absolute  mainPositionAvailable">Available <br/>Homes</h1>
                    <div className="col-8 availables">
                        <img src={Availables} className="availables opacityAnimation"  alt="availables" />
                    </div>
                </div>
            </div>
         </div>
         <div className="available1">
          <div className="availableBackImage opacityAnimation">
          </div>
           <div className="availableContent2">
           <h1 className="mainTextAvailable">Available <br/>Homes<br/></h1>
            <h1 className="contentTextAvailable "> At the Sora you'll enjoy bright, open studios equipped with a Murphy Bed, built-in desk, tons of shelving, large kitchens, and in-unit washer and dryer. Studio living should come with all the bells and whistles. </h1>
           
                <Link  className="contentButton1 " to="/available">See The Spaces</Link>
           </div>
        </div>
    </section>
  );
};

export default AvailableContent;
