import React, { Component } from "react";
import "./AvailableBody.css";
import Footer from "../Footer/footer";
import AvailableUnit from "./AvailableUnit/AvailableUnit";
import InterestApply from "./InterestApply/InterestApply";
import { withRouter } from "react-router-dom";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

class AvailableBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leadBox: false,
      name: "",
      email: "",
      mobile: "",
      leadData: [],
    };
    this.onchange = this.onchange.bind(this);
    this.handelLeadBox = this.handelLeadBox.bind(this);
  }
  componentDidMount() {
    // console.log("uiuiuiui"+this.props.location.pathname);
    // const query = new URLSearchParams(this.props.location.search);
    // const token = query.get("lead");
    if (this.props.location.pathname === "/contact") {
      this.setState({ leadBox: true });
    }
  }
  handelLeadBox(data) {
    // console.log(data);
    this.setState({ leadData: data });
    this.setState({ leadBox: !this.state.leadBox });
  }
  SubmitLeadBox() {
    if (
      this.state.email !== "" &&
      this.state.mobile !== "" &&
      this.state.name !== ""
    ) {
      if (this.checkEmail()) {
        try {
          fetch(`https://api.axallant.com/api/user/lead`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + sessionStorage.getItem("access_token"),
            },
            body: JSON.stringify({
              email: this.state.email,
              mobile: this.state.mobile,
              name: this.state.name,
              rentalEntity: this.state.leadData.unit,
              property: "Sora",
            }),
          }).then((response) => {
            if (response.status === 200) {
              response.json().then((data) => {
                if (data.length !== 0) {
                  store.addNotification({
                    message:
                      "Thank you for your interest in Sora Apartments. A representative will connect with you soon!",
                    type: "success",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                    },
                  });
                  this.setState({ leadBox: !this.state.leadBox });
                } else {
                  console.log("No data!");
                }
              });
            } else {
              console.log("Error in processing.Try again later!");
            }
          });
        } catch (error) {
          console.error(error);
        }
      } else {
        store.addNotification({
          title: "Alert!",
          message: "Email address is invalid",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 4000,
            onScreen: true,
          },
        });
      }
    } else {
      store.addNotification({
        title: "Alert!",
        message: "please fill the fields",
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 4000,
          onScreen: true,
        },
      });
    }
  }
  async onchange(e) {
    let { name, value } = e.target;
    if (name === "mobile") {
      let phone = value.replace(/[^\d]/g, "");
      value = phone;
      if (phone.length === 10) {
        value = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      } else if (phone.length > 6) {
        value = phone.replace(/(\d{3})(\d{3})/, "($1) $2-");
      } else if (phone.length > 3) {
        value = phone.replace(/(\d{3})/, "($1) ");
      } else if (phone.length > 1) {
        value = phone.replace(/(\d{1})/, "($1");
      }
      if (phone.length > 10) {
        await this.setState({ [name]: this.state[name] });
      } else {
        await this.setState({ [name]: value });
      }
    } else {
      await this.setState({ [name]: value });
    }
  }
  checkEmail() {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      !re.test(String(this.state.email).toLowerCase()) ||
      this.state.email === ""
    ) {
      return false;
    }
    return true;
  }
  render() {
    return (
      <>
        <div className={this.handleBodyStyle()} style={{ margin: 0 }}>
          <ReactNotification />
          <AvailableUnit
            property={this.props.property}
            handelLeadBox={this.handelLeadBox}
          />
          <div className="fontStyle" style={{ padding: "3vw" }}>
            <div className="row">
              <div className="col">
                <h3
                  style={{
                    color: "#d15428",
                    fontFamily: "'Montserrat', sans-serif",
                    fontWeight: "1000",
                  }}
                >
                  About Sora Apartments
                </h3>
                <p>
                  Located in Seattle's University District, Sora is a modern,
                  brand-new boutique apartment building with 54 well-crafted
                  homes. Sora will be opening August 2021! We are now
                  pre-leasing!! *MFTE rent prices subject to 2021 Office of
                  Housing changes.* Unit Amenities - Wide plank hardwood styled
                  flooring Kitchens come equipped with modern stainless steel
                  fridge, microwave, and a built in cooktop. Select units have a
                  full range. Washer and dryer in all units Floor-to-ceiling
                  windows in select units with black-out roller blinds Hard
                  surface quartz counters in kitchen and bathrooms as well as
                  tile kitchen backsplash Air conditioning ports ready for
                  portable units and fully installed air- conditioning in all
                  top-floor units Juliet balconies and step-out balconies in
                  select units
                </p>
              </div>
              <div className="col">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2687.3241484783102!2d-122.32232788474603!3d47.658698992371335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549014f4338e69af%3A0xc9721e3c339a4595!2s4239%208th%20Ave%20NE%2C%20Seattle%2C%20WA%2098105%2C%20USA!5e0!3m2!1sen!2slk!4v1620326227465!5m2!1sen!2slk"
                  className="soraMap"
                  allowFullScreen=""
                  title="map"
                  loading="lazy"
                ></iframe>
              </div>
            </div>

            {/* <h4  style={{color: "#d15428",fontFamily: "'Montserrat', sans-serif",fontWeight: "300"}}>Community Amenities</h4>
          <div className="row"> 
            <div className="card col m-4" style={{minWidth: "200px",maxWidth: "400px"}}>
            <img className="card-img-top" src={laundry} alt="laundry"/>
            <div className="card-body text-center">
              <p className="card-text">Laundry Facilities</p>
            </div>
          </div>

          <div className="card col m-4" style={{minWidth: "300px",maxWidth: "400px"}}>
            <img className="card-img-top" src={Furnished} alt="Furnished" />
            <div className="card-body text-center">
              <p className="card-text">Furnished Units Available</p>
            </div>
          </div>
          <div className="card col m-4" style={{minWidth: "200px",maxWidth: "400px"}}>
            <img className="card-img-top" src={evaluator} alt="evaluator"/>
            <div className="card-body text-center">
              <p className="card-text">Elevator</p>
            </div>
          </div>
          <div className="card col m-4" style={{minWidth: "200px",maxWidth: "400px"}}>
            <img className="card-img-top" src={roof} alt="roof"/>
            <div className="card-body text-center">
              <p className="card-text">Roof Terrace</p>
            </div>
          </div>
          </div> */}

            <h3
              style={{
                color: "#d15428",
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: "1000",
              }}
            >
              Neighborhood
            </h3>
            <br></br>
            <p>
              The University District, affectionately dubbed the U District, is
              home to the preeminent University of Washington. Lending historic
              architecture and blooming cherry blossoms in the spring, the
              University of Washington contains a range of gardens, galleries,
              and theaters in addition to sports complexes and a medical center.
              Should you choose to rent in the U District, you will have the
              opportunity to bike the Burke-Gilman trail, kayak Union and
              Portage Bays, savor cocktails and a film at Sundance Cinemas,
              swing by the Saturday Farmers Market, celebrate touchdowns at
              Husky Stadium, check out the independent restaurants and funky
              shops along the Ave, and attend the annual U District StreetFair.
              The U District sits about five miles north of Downtown Seattle,
              with convenience to Green Lake Park, Woodland Park Zoo, the
              Fremont Troll, and Gas Works Park.
            </p>
            <br></br>
            <h3
              style={{
                color: "#d15428",
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: "1000",
              }}
            >
              Education
            </h3>
            <br></br>
            <h4
              style={{
                color: "#d15428",
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: "300",
              }}
            >
              Colleges & Universities
            </h4>
            <p>
              Sora Apartments is within 5 minutes or 1.2 miles from University
              of Washington at Seattle. It is also near Seattle Pacific
              University and North Seattle College.
            </p>

            <table className="table ">
              <caption>List of college</caption>
              <thead className="bgPrimary">
                <tr>
                  <th scope="col">College</th>
                  <th scope="col">Drive</th>
                  <th scope="col">Distance</th>
                </tr>
              </thead>
              <tbody className="bg-light">
                <tr>
                  <td>University of Washington at Seattle</td>
                  <td>5 min</td>
                  <td>1.2 mi</td>
                </tr>
                <tr>
                  <td>Seattle Pacific University</td>
                  <td>8 min</td>
                  <td>2.7 mi</td>
                </tr>
                <tr>
                  <td>North Seattle College</td>
                  <td>9 min</td>
                  <td>3.7 mi</td>
                </tr>
              </tbody>
            </table>
            <br></br>
            <h3
              style={{
                color: "#d15428",
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: "1000",
              }}
            >
              Transportation
            </h3>
            <br></br>
            <p>
              Sora Apartments is near Kenmore Air Harbor Inc, located 10.4 miles
              or 21 minutes away, and Seattle-Tacoma International, located 19.3
              miles or 36 minutes away.
            </p>

            <table className="table ">
              <caption>List of Commuter Rail</caption>
              <thead className="bgPrimary">
                <tr>
                  <th scope="col">Commuter Rail</th>
                  <th scope="col">Drive</th>
                  <th scope="col">Distance</th>
                </tr>
              </thead>
              <tbody className="bg-light">
                <tr>
                  <td>King Street (Seattle) Station</td>
                  <td>10 min</td>
                  <td>5.2 mi</td>
                </tr>
              </tbody>
            </table>

            <table className="table ">
              <caption>List of airports</caption>
              <thead className="bgPrimary">
                <tr>
                  <th scope="col">Airports</th>
                  <th scope="col">Drive</th>
                  <th scope="col">Distance</th>
                </tr>
              </thead>
              <tbody className="bg-light">
                <tr>
                  <td>Kenmore Air Harbor Inc</td>
                  <td>21 min</td>
                  <td>10.4 mi</td>
                </tr>
                <tr>
                  <td>Seattle-Tacoma International</td>
                  <td>36 min</td>
                  <td>19.3 mi</td>
                </tr>
              </tbody>
            </table>
            <br></br>
            <h3
              style={{
                color: "#d15428",
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: "1000",
              }}
            >
              Points of Interest
            </h3>
            <br></br>
            <p>Time and distance from Sora Apartments.</p>
            <table className="table ">
              <caption>List of shopping centers</caption>
              <thead className="bgPrimary">
                <tr>
                  <th scope="col">Shopping Centers</th>
                  <th scope="col">Walk</th>
                  <th scope="col">Distance</th>
                </tr>
              </thead>
              <tbody className="bg-light">
                <tr>
                  <td>Strip Center at 700 NE 45th St</td>
                  <td>5 min</td>
                  <td>0.2 mi</td>
                </tr>
                <tr>
                  <td>Seattle-Tacoma International</td>
                  <td>5 min</td>
                  <td>0.2 mi</td>
                </tr>
                <tr>
                  <td>University District</td>
                  <td>11 min</td>
                  <td>0.5 mi</td>
                </tr>
              </tbody>
            </table>
            <br></br>
            <p>
              Sora Apartments has 3 shopping centers within 0.5 miles, which is
              about a 11-minute walk. The miles and minutes will be for the
              farthest away property.
            </p>
            <table className="table ">
              <caption>List of park</caption>
              <thead className="bgPrimary">
                <tr>
                  <th scope="col">Parks and Recreation</th>
                  <th scope="col">Drive</th>
                  <th scope="col">Distance</th>
                </tr>
              </thead>
              <tbody className="bg-light">
                <tr>
                  <td>University of Washington Fish Collection</td>
                  <td>3 min</td>
                  <td>0.9 mi</td>
                </tr>
                <tr>
                  <td>Burke Museum of Natural History</td>
                  <td>4 min</td>
                  <td>1.0 mi</td>
                </tr>
                <tr>
                  <td>Gas Works Park</td>
                  <td>4 min</td>
                  <td>1.3 mi</td>
                </tr>
                <tr>
                  <td>Cowen Park</td>
                  <td>5 min</td>
                  <td>1.7 mi</td>
                </tr>
                <tr>
                  <td>Ravenna Park</td>
                  <td>6 min</td>
                  <td>2.0 mi</td>
                </tr>
              </tbody>
            </table>
            <br></br>
            <p>
              Sora Apartments has 5 parks within 2.0 miles, including University
              of Washington Fish Collection, Burke Museum of Natural History,
              and Gas Works Park.
            </p>
            <table className="table  ">
              <caption>List of park</caption>
              <thead className="bgPrimary">
                <tr>
                  <th scope="col">Parks and Recreation</th>
                  <th scope="col">Drive</th>
                  <th scope="col">Distance</th>
                </tr>
              </thead>
              <tbody className="bg-light">
                <tr>
                  <td>Fort Lawton Military Reserve</td>
                  <td>17 min</td>
                  <td>6.1 mi</td>
                </tr>
                <tr>
                  <td>Keyport Naval Reservation</td>
                  <td>80 min</td>
                  <td>35.0 mi</td>
                </tr>
              </tbody>
            </table>
            <br></br>
            <p>
              Sora Apartments is 6.1 miles from Fort Lawton Military Reserve,
              and is convenient to other military bases, including Keyport Naval
              Reservation.
            </p>
          </div>

          <InterestApply />
          <Footer data={this.props.footerData} />
        </div>
        {this.state.leadBox && (
          <>
            <div
              className="modal fade bd-example-modal-xl show"
              id="leadModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              style={{ display: "block", paddingRight: "15px" }}
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div className="modal-content ">
                  <div className="modal-header shadow ModalAvailable">
                    <h5
                      className="modal-title  textAvailableUnitModal"
                      id="exampleModalLabel"
                    >
                      Enquire
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={() => this.handelLeadBox()}
                      aria-label="Close"
                      style={{ color: "#f2360c" }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body bodyAvailable pt-3  pb-2">
                    <h1
                      className="availableTableHead pt-3 pb-3  ml-3"
                      style={{ fontWeight: "bold", color: "" }}
                    >
                      Few Units are Available
                    </h1>
                    <div className="row contactAvailable pt-1  pb-3">
                      <div className="input-group mb-4 ">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="basic-addon1"
                            style={{
                              background: "#d15428",
                              color: "#fff",
                              border: "1px solid #d15428",
                            }}
                          >
                            Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span>
                        </div>
                        <input
                          type="text"
                          name="name"
                          value={this.state.name}
                          onChange={this.onchange}
                          className="form-control"
                          placeholder="John Doe"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          style={{ border: "1px solid #d15428" }}
                        />
                      </div>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="basic-addon1"
                            style={{
                              background: "#d15428",
                              color: "#fff",
                              border: "1px solid #d15428",
                            }}
                          >
                            Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span>
                        </div>
                        <input
                          type="email"
                          name="email"
                          value={this.state.email}
                          onChange={this.onchange}
                          className="form-control"
                          placeholder="johndoe@gmail.com"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          style={{ border: "1px solid #d15428" }}
                        />
                      </div>
                      <div className="input-group mb-3 ">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="basic-addon1"
                            style={{
                              background: "#d15428",
                              color: "#fff",
                              border: "1px solid #d15428",
                            }}
                          >
                            Phone No{" "}
                          </span>
                        </div>
                        <input
                          type="text"
                          name="mobile"
                          value={this.state.mobile}
                          onChange={this.onchange}
                          style={{ border: "1px solid #d15428" }}
                          className="form-control"
                          placeholder="(814) 300 - 8152 "
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal-footer pt-4 "
                    style={{ borderTop: "1px solid #d15428" }}
                  >
                    <div className=" text-right ">
                      {" "}
                      {/* <span
                        className="availableUnitButtonModal "
                        data-dismiss="modal"
                        onClick={() => this.handelLeadBox()}
                      >
                        {" "}
                        Close
                      </span> */}
                    </div>
                    <div
                      className=" text-left "
                      onClick={() => this.SubmitLeadBox()}
                    >
                      {" "}
                      <span className="primaryAvailableUnitButtonModal ">
                        Send
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-backdrop fade show"></div>
          </>
        )}
      </>
    );
  }
  handleBodyStyle() {
    let classes = "";
    window.scroll(0, 0);
    classes += this.props.menuOn === 1 ? "willetBody hide" : "willetBody ";
    return classes;
  }
}
export default withRouter(AvailableBody);
